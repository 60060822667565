import React, { Component, Fragment, ReactNode } from "react";
import { BackendFactory, Organization, OrganizationObserver } from "@sade/data-access";
import { Button, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Loader from "../../../ui/loader";
import ErrorDialog from "../../../ui/error-dialog";
import ConfirmationDialog from "../../../ui/confirmation-dialog";
import { translations } from "../../../../generated/translationHelper";
import { AdminCellItem } from "../util-components/admin-cell-item";

interface UserPermissions {
  createOrganization: boolean;
  deleteOrganization: boolean;
}

const NO_PERMISSIONS: UserPermissions = {
  createOrganization: false,
  deleteOrganization: false,
};

interface Props {
  parentOrganization: Organization;
}

interface State {
  loading: boolean;
  children?: Organization[];
  userPermissions?: UserPermissions;
  newOrganizationName?: string;
  newOrganizationMaxSecureCode: string;
  organizationToDelete?: Organization;
  errorMsg?: string;
}

export default class OrganizationChildList extends Component<Props, State> implements OrganizationObserver {
  public constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      newOrganizationMaxSecureCode: "0",
    };
  }

  public async componentDidMount(): Promise<void> {
    await this.performUpdates();
    this.props.parentOrganization.addObserver(this);
  }

  public async componentDidUpdate(prevProps: Readonly<Props>): Promise<void> {
    if (prevProps.parentOrganization.getId() !== this.props.parentOrganization.getId()) {
      prevProps.parentOrganization.removeObserver(this);
      await this.performUpdates();
      this.props.parentOrganization.addObserver(this);
    }
  }

  public componentWillUnmount(): void {
    this.props.parentOrganization.removeObserver(this);
  }

  public onChildrenChange(children: Organization[]): void {
    this.setState({ children });
  }

  private renderHeadings(): ReactNode | undefined {
    if (!this.state.loading && (this.state.children ?? []).length > 0) {
      return (
        <TableHead sx={{ display: "table-header-group" }}>
          <TableRow>
            <TableCell>{translations.admin.texts.name()}</TableCell>
            <TableCell>{translations.admin.texts.maxSecureCode()}</TableCell>
            <TableCell>{/* this is for the third column that contains the "delete" button */}</TableCell>
          </TableRow>
        </TableHead>
      );
    }
  }

  public renderChildList(): ReactNode {
    const children = [...(this.state.children ?? [])].sort((child1, child2) =>
      child1.getName().localeCompare(child2.getName())
    );

    if (children.length === 0) {
      return;
    }

    return children.map((child, index) => {
      return (
        <TableRow key={child.getId()} data-testid={"table-row"}>
          <TableCell>
            <AdminCellItem item={{ name: child.getName(), id: child.getId() }} data-testid={`organization-${index}`} />
          </TableCell>
          <TableCell>
            <Typography color="textPrimary" data-testid={`organization-maxsecurecode-${index}`}>
              {child.getMaxSecureCode()}
            </Typography>
          </TableCell>
          <TableCell align="right">
            <Button
              onClick={(): void => this.setState({ organizationToDelete: child })}
              variant="contained"
              color="primary"
              disabled={!this.state.userPermissions?.deleteOrganization || this.state.loading}
              data-testid={`organization-${index}-delete-button`}
            >
              {translations.common.buttons.delete()}
            </Button>
          </TableCell>
        </TableRow>
      );
    });
  }

  private async performUpdates(): Promise<void> {
    this.setState({
      loading: true,
    });
    await Promise.allSettled([this.loadChildren(), this.updateUserPermissions()]).finally(() => {
      this.setState({
        loading: false,
      });
    });
  }

  private async updateUserPermissions(): Promise<void> {
    try {
      const user = await BackendFactory.getOrganizationBackend().getCurrentUser();

      if (user) {
        const organizationId = this.props.parentOrganization.getId();
        const allowedActions = await Promise.all([
          user.hasPermissions(organizationId, "organizationsCreate"),
          user.hasPermissions(organizationId, "organizationsDelete"),
        ]);

        this.setState({
          userPermissions: {
            createOrganization: allowedActions[0],
            deleteOrganization: allowedActions[1],
          },
        });
      }
    } catch (err) {
      console.error("Failed to update user's permissions", err);
      this.setState({ userPermissions: NO_PERMISSIONS });
    }
  }

  private async loadChildren(): Promise<void> {
    const children = await this.props.parentOrganization.getDirectChildOrganizations();
    this.setState({
      children,
    });
  }

  private async performLoadableTask(taskName: string, task: () => Promise<void>): Promise<void> {
    this.setState({ loading: true });

    try {
      await task();
    } catch (err) {
      console.error(`Failed to complete task '${taskName}'`, err);
      this.setState({ errorMsg: `${taskName} failed: ${err instanceof Error ? err.message : JSON.stringify(err)}` });
    } finally {
      this.setState({ loading: false });
    }
  }

  private renderDeleteConfirmation(): ReactNode {
    if (!this.state.organizationToDelete) return;

    const organization = this.state.organizationToDelete;
    const deleteAction = (): void =>
      void this.performLoadableTask("Delete organization", async (): Promise<void> => {
        this.setState({ organizationToDelete: undefined });

        const organizationIndex =
          this.state.children?.findIndex((child) => child.getId() === organization.getId()) ?? -1;

        if (organizationIndex === -1) {
          throw new Error("Organization is not a valid child");
        }

        try {
          await organization.delete();
        } catch (err) {
          console.error("deleteAction", err);

          if (err instanceof Error && err.message && /has child/.test(err.message)) {
            this.setState({ errorMsg: translations.admin.texts.failedToDeleteOrganizationHasChildren() });
          } else {
            this.setState({ errorMsg: translations.admin.texts.failedToDeleteOrganization() });
          }
        }
      });
    return (
      <ConfirmationDialog
        title={translations.admin.texts.deleteOrganization()}
        message={translations.admin.texts.deleteOrganizationConfirmation({ organization: organization.getName() })}
        onConfirm={deleteAction}
        onCancel={(): void => this.setState({ organizationToDelete: undefined })}
      />
    );
  }

  private renderError(): ReactNode {
    if (this.state.errorMsg) {
      return (
        <ErrorDialog errorMsg={this.state.errorMsg} onClose={(): void => this.setState({ errorMsg: undefined })} />
      );
    }
  }

  private renderLoader(): ReactNode {
    return <Loader size="small" show={this.state.loading} />;
  }

  public render(): ReactNode {
    return (
      <Fragment>
        <Table data-testid="organizations-table">
          {this.renderHeadings()}
          <TableBody>{this.renderChildList()}</TableBody>
        </Table>
        {this.renderLoader()}
        {this.renderDeleteConfirmation()}
        {this.renderError()}
      </Fragment>
    );
  }
}
